// Note coordinates [octave, fifth] relative to C

const knowledge = {
  notes: {
    c: [0, 0],
    d: [-1, 2],
    e: [-2, 4],
    f: [1, -1],
    g: [0, 1],
    a: [-1, 3],
    b: [-2, 5],
    h: [-2, 5],
  },

  intervals: {
    unison: [0, 0],
    second: [3, -5],
    third: [2, -3],
    fourth: [1, -1],
    fifth: [0, 1],
    sixth: [3, -4],
    seventh: [2, -2],
    octave: [1, 0],
  },

  intervalFromFifth: [
    "second",
    "sixth",
    "third",
    "seventh",
    "fourth",
    "unison",
    "fifth",
  ],

  intervalsIndex: [
    "unison",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "octave",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
  ],

  // linear index to fifth = (2 * index + 1) % 7
  fifths: ["f", "c", "g", "d", "a", "e", "b"],
  accidentals: ["bb", "b", "", "#", "x"],

  sharp: [-4, 7],
  A4: [3, 3],

  durations: {
    0.25: "longa",
    0.5: "breve",
    1: "whole",
    2: "half",
    4: "quarter",
    8: "eighth",
    16: "sixteenth",
    32: "thirty-second",
    64: "sixty-fourth",
    128: "hundred-twenty-eighth",
  },

  qualityLong: {
    P: "perfect",
    M: "major",
    m: "minor",
    A: "augmented",
    AA: "doubly augmented",
    d: "diminished",
    dd: "doubly diminished",
  },

  alterations: {
    perfect: ["dd", "d", "P", "A", "AA"],
    minor: ["dd", "d", "m", "M", "A", "AA"],
  },

  symbols: {
    min: ["m3", "P5"],
    m: ["m3", "P5"],
    "-": ["m3", "P5"],

    M: ["M3", "P5"],
    "": ["M3", "P5"],

    "+": ["M3", "A5"],
    aug: ["M3", "A5"],

    dim: ["m3", "d5"],
    o: ["m3", "d5"],

    maj: ["M3", "P5", "M7"],
    dom: ["M3", "P5", "m7"],
    ø: ["m3", "d5", "m7"],

    5: ["P5"],
  },

  chordShort: {
    major: "M",
    minor: "m",
    augmented: "aug",
    diminished: "dim",
    "half-diminished": "7b5",
    power: "5",
    dominant: "7",
  },

  stepNumber: {
    unison: 1,
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    octave: 8,
    ninth: 9,
    eleventh: 11,
    thirteenth: 13,
  },

  // Adjusted Shearer syllables - Chromatic solfege system
  // Some intervals are not provided for. These include:
  // dd2 - Doubly diminished second
  // dd3 - Doubly diminished third
  // AA3 - Doubly augmented third
  // dd6 - Doubly diminished sixth
  // dd7 - Doubly diminished seventh
  // AA7 - Doubly augmented seventh
  intervalSolfege: {
    dd1: "daw",
    d1: "de",
    P1: "do",
    A1: "di",
    AA1: "dai",
    d2: "raw",
    m2: "ra",
    M2: "re",
    A2: "ri",
    AA2: "rai",
    d3: "maw",
    m3: "me",
    M3: "mi",
    A3: "mai",
    dd4: "faw",
    d4: "fe",
    P4: "fa",
    A4: "fi",
    AA4: "fai",
    dd5: "saw",
    d5: "se",
    P5: "so",
    A5: "si",
    AA5: "sai",
    d6: "law",
    m6: "le",
    M6: "la",
    A6: "li",
    AA6: "lai",
    d7: "taw",
    m7: "te",
    M7: "ti",
    A7: "tai",
    dd8: "daw",
    d8: "de",
    P8: "do",
    A8: "di",
    AA8: "dai",
  },
};

export default knowledge;
